import React, { useState, useMemo } from 'react';
import { Col, Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTeacherStudentData } from '../hooks/useApi';
import Loading from '../components/waiting';

const StudentRow = ({ student }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { isLoading, studentData } = useTeacherStudentData(student.student_id, modalIsOpen);
    const [activeText, setActiveText] = useState(null);

    // Safely calculate the text response count
    const responseTextCount = useMemo(() => {
        return studentData?.responses?.reduce((count, response) => {
            return count + (response.texts ? response.texts.length : 0);
        }, 0) || 0;
    }, [studentData]);

    const isStudentActive = !!student.name;

    const onToggle = () => {
        if (isStudentActive) {
            setModalIsOpen(!modalIsOpen);
        }
    };

    const onSelectText = (text) => {
        setActiveText(text);
    };

    const downloadHtml = (htmlContent, filename = 'response.html') => {
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
        URL.revokeObjectURL(url);
    };
    const downloadAllAsSingleHtml = () => {
        // Combine all the HTML content into a single string
        const combinedHtmlContent = studentData?.responses
            .map((response) =>
                response.texts
                    .map(
                        (text) =>
                            `<div style="margin-bottom: 20px;">
                                <h2>Question ${text.question}</h2>
                                ${text.html}
                            </div>`
                    )
                    .join('')
            )
            .join('');
    
        // Wrap the combined content in a basic HTML structure
        const fullHtmlDocument = `
            <!DOCTYPE html>
            <html>
                <head>
                    <title>${student.name || 'Responses'}</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            line-height: 1.5;
                            margin: 20px;
                        }
                        h2 {
                            color: #333;
                            border-bottom: 1px solid #ddd;
                            padding-bottom: 5px;
                        }
                        div {
                            margin-bottom: 20px;
                        }
                    </style>
                </head>
                <body>
                    <h1>${student.name || 'Student Responses'}</h1>
                    ${combinedHtmlContent || '<p>No responses available.</p>'}
                </body>
            </html>
        `;
    
        // Create and download the file
        const blob = new Blob([fullHtmlDocument], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${student.name || 'responses'}.html`;
        a.click();
        URL.revokeObjectURL(url);
    };
    
    return (
        <>
            <Modal isOpen={modalIsOpen} className="student-uploads-modal">
                <ModalHeader toggle={onToggle}>{student.name}</ModalHeader>
                <ModalBody>
                    <Row className="h-100 no-gutters" style={{ margin: '0px', maxHeight: '70vh', overflowY: 'auto' }}>
                        <Col xs="4" className="file-selection-column">
                            <div className="upload-item-type-header">Responses</div>
                            {isLoading ? (
                                <Loading>Loading responses...</Loading>
                            ) : responseTextCount > 0 ? (
                                studentData.responses.map((response, idx) => (
                                    <React.Fragment key={idx}>
                                        {response.texts.map((text, idx) => (
                                            <div
                                                key={idx}
                                                className="upload-item"
                                                onClick={() => onSelectText(text)}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <div>
                                                    <strong>Question {text.question}</strong>
                                                </div>
                                                <div style={{ color: 'gray' }}>
                                                    Order: {text.order}
                                                </div>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent triggering `onClick` for the parent
                                                        downloadHtml(text.html, `response-${text.id}.html`);
                                                    }}
                                                    className="btn btn-sm btn-primary mt-2"
                                                >
                                                    Download
                                                </button>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))
                            ) : (
                                <div className="no-upload-item">No response pages available</div>
                            )}
                            {/* doanload all */}
                            {responseTextCount > 0 && (
                                <button
                                    style={{marginTop: "10px"}}
                                    onClick={downloadAllAsSingleHtml}
                                    className="btn btn-sm btn-secondary mb-3"
                                >
                                    Download All as Single Document
                                </button>
                            )}

                        </Col>

                        <Col
                            xs={activeText ? 8 : 5}
                            className="file-display-column"
                            style={{ padding: '10px' }}
                        >
                            {activeText ? (
                                <div
                                    style={{ overflow: 'auto', height: '100%' }}
                                    dangerouslySetInnerHTML={{ __html: activeText.html }}
                                />
                            ) : (
                                <div className="no-text">Please select a response</div>
                            )}
                
                        </Col>

                    </Row>
                </ModalBody>
            </Modal>
            <tr
                key={student.student}
                onClick={onToggle}
                style={isStudentActive ? { cursor: 'pointer' } : {}}
            >
                <td>{student.student}</td>
                <td className="text-center">{student.login_code}</td>
                <td>{student.name}</td>
                <td className="text-center">{student.mc}</td>
                <td className="text-center">{student.fr}</td>
                <td className="text-center">{student.scored}</td>
            </tr>
        </>
    );
};

export default StudentRow;


//LEGAGCY

// import React, {useState} from 'react';
// import {Col, Row, Modal, ModalBody, ModalHeader} from 'reactstrap';
// import {useTeacherStudentData} from '../hooks/useApi';
// import Loading from '../components/waiting';
// import ResponseImage from '../response/ResponseImage';

// const StudentRow = ({student}) => {
//     const [modalIsOpen, setModalIsOpen] = useState(false);
//     const {isLoading, studentData, responseImageCount} = useTeacherStudentData(
//         student.student_id,
//         modalIsOpen
//     );
//     const [activeImage, setActiveImage] = useState(null);

//     const isStudentActive = !!student.name;
//     const onToggle = () => {
//         if (isStudentActive) {
//             setModalIsOpen(!modalIsOpen);
//         }
//     };

//     const onSelectFile = file => {
//         setActiveImage(file);
//     };

//     const imageContainerStyles = {
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//         alignItems: 'center',
//     };

//     return (
//         <>
//             <Modal isOpen={modalIsOpen} className='student-uploads-modal'>
//                 <ModalHeader toggle={onToggle}>{student.name}</ModalHeader>
//                 <ModalBody>
//                     <Row className=' h-100 no-gutters' style={{margin: '0px'}}>
//                         <Col xs='4' className='file-selection-column'>
//                             {/*<div className='upload-item-type-header'>Name Page</div>*/}
//                             {/*{studentData?.namePage?.image ? (*/}
//                             {/*    <div*/}
//                             {/*        className='upload-item'*/}
//                             {/*        onClick={() => onSelectFile(studentData.namePage.image)}*/}
//                             {/*    >*/}
//                             {/*        {studentData?.namePage?.image?.qr_code}*/}
//                             {/*    </div>*/}
//                             {/*) : isLoading ? (*/}
//                             {/*    <div>...</div>*/}
//                             {/*) : (*/}
//                             {/*    <div className='no-upload-item'>No Name Page has been uploaded</div>*/}
//                             {/*)}*/}
//                             {/*<div className='upload-item-type-header'>Multiple Choice Page</div>*/}
//                             {/*{studentData?.mcPage?.image ? (*/}
//                             {/*    <div*/}
//                             {/*        className='upload-item'*/}
//                             {/*        onClick={() => onSelectFile(studentData.mcPage.image)}*/}
//                             {/*    >*/}
//                             {/*        {studentData?.mcPage?.image?.qr_code}*/}
//                             {/*    </div>*/}
//                             {/*) : isLoading ? (*/}
//                             {/*    <div>...</div>*/}
//                             {/*) : (*/}
//                             {/*    <div className='no-upload-item'>*/}
//                             {/*        No Multiple Choice Page has been uploaded*/}
//                             {/*    </div>*/}
//                             {/*)}*/}
//                             <div className='upload-item-type-header'>Responses</div>
//                             {responseImageCount > 0 ? (
//                                 studentData.responses.map((response, idx) => (
//                                     <React.Fragment key={idx}>
//                                         {response.images.map((image, idx) => (
//                                             <div
//                                                 key={idx}
//                                                 className='upload-item'
//                                                 onClick={() => onSelectFile(image)}
//                                                 style={{
//                                                     display: 'flex',
//                                                     flexDirection: 'column',
//                                                     justifyContent: 'flex-start',
//                                                 }}
//                                             >
//                                                 <div style={{display: 'block'}}>
//                                                     {image?.qr_code}
//                                                 </div>
//                                                 {activeImage?.id === image.id ? (
//                                                     <div style={{display: 'block', color: 'gray'}}>
//                                                         Uploaded as: {image.filename}
//                                                     </div>
//                                                 ) : null}
//                                             </div>
//                                         ))}
//                                     </React.Fragment>
//                                 ))
//                             ) : isLoading ? (
//                                 <Loading>Loading images...</Loading>
//                             ) : (
//                                 <div className='no-upload-item'>No response pages available</div>
//                             )}
//                         </Col>

//                         <Col
//                             xs={activeImage ? 8 : 5}
//                             className='file-display-column'
//                             style={{padding: '10px'}}
//                         >
//                             {activeImage ? (
//                                 <ResponseImage
//                                     imageContainerStyles={imageContainerStyles}
//                                     key={activeImage?.id}
//                                     id={activeImage?.id}
//                                     url={activeImage?.url}
//                                     expand={false}
//                                 />
//                             ) : (
//                                 <div className='no-image'>Please select an image</div>
//                             )}
//                         </Col>
//                     </Row>
//                 </ModalBody>
//             </Modal>
//             <tr
//                 key={student.student}
//                 onClick={onToggle}
//                 style={isStudentActive ? {cursor: 'pointer'} : {}}
//             >
//                 <td>{student.student}</td>
//                 <td className='text-center'>{student.login_code}</td>
//                 <td>{student.name}</td>
//                 <td className='text-center'>{student.mc}</td>
//                 <td className='text-center'>{student.fr}</td>
//                 <td className='text-center'>{student.scored}</td>
//             </tr>
//         </>
//     );
// };

// export default StudentRow;
